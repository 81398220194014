<template>
  <div class="container page-section">
    <b-card :title="$t('relatorio.title')">
      <div class="row pt-3">
        <div v-if="isState || isAdm" class="col-lg-6 mt-1">
          <label for="input-1">Estado</label>
          <v-select
            id="input-1"
            v-model="estado"
            :options="estados"
            :placeholder="$t('geral.placeholders.selecione-estado')"
            label="name"
            :disabled="estadoSelectDisabled"
          ></v-select>
        </div>

        <div v-else class="col-lg-6 mt-1">
          <label for="input-1">Cidade</label>
          <v-select
            id="input-1"
            v-model="cidade"
            :placeholder="$t('geral.placeholders.selecione-estado')"
            label="name"
            :disabled="true"
          ></v-select>
        </div>

        <b-form-group
          v-show="!isMunicipio"
          :label="$t('relatorio.incluir-bairros')"
          label-for="input-2"
          class="col-lg-3 mt-1"
        >
          <b-form-radio-group
            id="input-2"
            v-model="getBairro"
            :options="confirmOptions"
            buttons
            button-variant="outline-success"
            size="md"
          ></b-form-radio-group>
          <br />
        </b-form-group>

        <b-form-group
          :label="$t('geral.labels.escolha-formato')"
          label-for="input-3"
          class="col-lg-3 mt-1"
        >
          <b-form-radio-group
            id="input-3"
            v-model="formato"
            :options="formatos"
            label="label"
            buttons
            button-variant="outline-success"
            size="md"
          ></b-form-radio-group>
          <br />
        </b-form-group>

        <div class="col-lg-6 mt-3">
          <label for="data-inicial"
            >{{ $t("geral.labels.data-inicial") }}
            <span style="color: #29B765;">
              {{ $t("geral.labels.data-iniciall") }}
            </span></label
          >

          <b-form-datepicker
            id="data-inicial"
            :placeholder="$t('relatorio.selecione-data-inicial')"
            v-model="dataInicio"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="col-lg-6 mt-3">
          <label for="data-final">{{ $t("geral.labels.data-final") }}</label>
          <b-form-datepicker
            id="data-final"
            :placeholder="$t('relatorio.selecione-data-final')"
            v-model="dataFim"
            class="mb-2"
          ></b-form-datepicker>
        </div>
        <div class="col-lg-12 pt-3">
          <p>
            <b>{{ $t("geral.labels.obs") }}</b>
            {{ $t("relatorio.obs") }}
          </p>
        </div>
      </div>
      <h1 v-if="!isDataInitEmpty && !isDataFimEmpty && !isDateValid()">
        Data inválida!
      </h1>
      <button
        class="btn btn-success"
        @click="openLink()"
        :disabled="enableButtom()"
      >
        {{ $t("geral.buttons.gerar-relatorio") }}
      </button>
    </b-card>
  </div>
</template>
<script>
import Service from "../services/ApiServices";

export default {
  data() {
    return {
      baseUrl: "https://www.fiquenolar.ifce.edu.br/fiquenolar",
      // baseUrl: "http://localhost:8080/fiquenolar",
      rotaRelatorioByEstado: "/relatorios/por-estado-e-intervalo-de-datas",
      rotaRelatorioByEstadoBairro:
        "/relatorios/por-estado-e-contando-por-bairro",
      rotaRelatorioByMunicipio:
        "/relatorios/por-municipio-e-intervalo-de-datas",
      estados: [],
      estado: "",
      cidade: "",
      estadoSelectDisabled: true,
      dataInicio: "",
      dataFim: "",
      dateToDay: "",
      formatos: ["PDF", "XLS"],
      formato: "PDF",
      confirmOptions: [
        { text: "", value: true, translate_key: "sim" },
        { text: "", value: false, translate_key: "nao" }
      ],
      getBairro: true
    };
  },
  created() {
    this.dateToDay = this.getDateToDay();
    this.translateRadio();

    if (localStorage.adminnastro) {
      this.getEstados();
      this.estadoSelectDisabled = false;
    } else if (localStorage.partneraccess) {
      this.getLocalById(localStorage.partneraccess);
    }
  },
  computed: {
    isAdm() {
      return localStorage.adminnastro;
    },
    isState() {
      return localStorage.partnerLevel === "state";
    },
    isMunicipio() {
      return !(
        localStorage.adminnastro || localStorage.partnerLevel === "state"
      );
    },
    isDataInitEmpty() {
      return this.dataInicio === "";
    },
    isDataFimEmpty() {
      return this.dataFim === "";
    }
  },
  methods: {
    isDateValid() {
      let inicio = new Date(this.dataInicio + "T00:00:00").getTime();
      let fim = new Date(this.dataFim + "T00:00:00").getTime();

      if (inicio < new Date("2020-04-06T00:00:00").getTime()) {
        return false;
      }

      if (inicio > this.dateToDay.getTime() || fim > this.dateToDay.getTime()) {
        return false;
      }

      let result = fim - inicio;
      return result >= 0;
    },

    getDateToDay() {
      let today = new Date(Date.now());
      let monthFormated = today.getMonth() + 1;
      if (monthFormated < 10) {
        monthFormated = `0${monthFormated}`;
      }
      let dayFormated = today.getDate();
      if (dayFormated < 10) {
        dayFormated = `0${dayFormated}`;
      }
      return new Date(
        `${today.getFullYear()}-${monthFormated}-${dayFormated}T00:00:00`
      );
    },

    async getEstados() {
      const request = new Service();
      const resp = await request.getAll({ sort: "nome", size: 27 }, "estados");
      if (resp) {
        this.estados = resp.content;
      }
    },

    async getLocalById(id = "") {
      const request = new Service();
      let resp = await request.getById(id, "estados/" + id);
      if (resp) {
        this.estado = resp;
      }
      resp = await request.getById(id, "cidades/" + id);
      if (resp) {
        this.estado = resp.state;
        this.cidade = resp.nome;
      }
    },

    async getRelatorio() {
      const request = new Service();
      const resp = await request.getAll(
        {
          estado: this.estado.name,
          inicio: this.normalizeData(this.dataInicio),
          fim: this.normalizeData(this.dataFim),
          formato: this.formato
        },
        "relatorios/por-estado-e-intervalo-de-datas"
      );
      if (resp) {
        this.estados = resp.content;
      }
    },

    normalizeDataInicio(data = "") {
      return data + "T00:00:00.000";
    },

    normalizeDataFim(data = "") {
      return data + "T23:59:59.999";
    },

    enableButtom() {
      if (
        this.estado != "" &&
        this.estado != undefined &&
        this.estado != null &&
        this.dataInicio != "" &&
        this.dataInicio != undefined &&
        this.dataFim != null &&
        this.dataFim != "" &&
        this.dataFim != undefined &&
        this.dataFim != null &&
        this.formato != "" &&
        this.formato != undefined &&
        this.formato != null &&
        this.isDateValid()
      )
        return false;
      else return true;
    },

    getRelatorioFilter() {
      if (!this.isState) {
        return `?estado=${this.estado.name}&municipio=${
          this.cidade
        }&inicio=${this.normalizeDataInicio(
          this.dataInicio
        )}&fim=${this.normalizeDataFim(this.dataFim)}&formato=${this.formato}`;
      }
      return `?estado=${this.estado.name}&inicio=${this.normalizeDataInicio(
        this.dataInicio
      )}&fim=${this.normalizeDataFim(this.dataFim)}&formato=${this.formato}`;
    },

    openLink() {
      var link = document.createElement("a");

      let path = this.baseUrl;
      if (this.isMunicipio) {
        path += this.rotaRelatorioByMunicipio;
      } else {
        path += this.getBairro
          ? this.rotaRelatorioByEstadoBairro
          : this.rotaRelatorioByEstado;
      }
      path += this.getRelatorioFilter();

      link.href = path;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    },

    translateRadio() {
      for (let key of this.confirmOptions) {
        key.text = this.$t("geral.labels." + key.translate_key);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page-section {
  padding: 3rem 0;
}

h1 {
  font-size: 1rem;
  color: red;
}
</style>
